import {
  NavigationPaths,
} from 'constants/navigation';

import {
  Bookings,
  Unassigned,
  SchedulerV2,
} from 'ui/screens/bookings';

export const BookingRoutes = [
  {
    path: NavigationPaths.Bookings,
    element: <Bookings />,
    options: {
      protected: true,
    },
    children: [
      {
        path: NavigationPaths.BookingsUnassigned,
        element: <Unassigned />,
        options: {
          default: true,
          protected: true,
        },
      },
      {
        path: NavigationPaths.BookingSchedule,
        element: <SchedulerV2 />,
        options: {
          protected: true,
        },
      },
    ],
  },
];
