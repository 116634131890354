export const StorageApi = {

  set: async (
    key,
    value
  ) => {

    if (!key) {
      return;
    }

    key = JSON.stringify(key);
    value = JSON.stringify(value);

    return localStorage.setItem(
      key,
      value,
    );
  },

  get: async key => {

    if (!key) {
      return;
    }

    return JSON.parse(localStorage.getItem(JSON.stringify(key)));
  },

  reset: async () => localStorage.clear(),
};
