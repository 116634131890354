import React from 'react';

import {
  Triangle,
} from 'react-loader-spinner';

import {
  Position,
  FlexDirection,
  Color
} from 'constants/presentation';

import {
  ContainerFill,
  ContainerFillHV,
  Table
} from 'ui/widgets';

import {
  useUnassignedBookings,
} from 'hooks';

export const Unassigned = props => {

  const {
    unassignedBookings,
  } = useUnassignedBookings();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Client Ref',
        accessor: 'formattedId' // accessor is the "key" in the data
      },
      {
        Header: 'GS Ref',
        accessor: 'referenceId'
      },
      {
        Header: 'Arrival',
        accessor: 'arrival'
      },
      {
        Header: 'Departure',
        accessor: 'departure'
      },
      {
        Header: 'Adults',
        accessor: 'adults',
      },
      {
        Header: 'Children',
        accessor: 'children',
      },
      {
        Header: 'Small Children',
        accessor: 'smallChildren',
      },
      {
        Header: 'Days Booked',
        accessor: 'daysBooked',
      },
    ],
    []
  );

  if (unassignedBookings.loading) {

    return (

      <ContainerFillHV
        flexDirection={FlexDirection.Column}>
        <Triangle
          color={Color.LightBlue}
          height={150}
          width={150} />
        <div>Loading Unassigned Bookings</div>
      </ContainerFillHV>
    );
  }

  return (
    <ContainerFill
      padding={'15px'}
      position={Position.Relative}
      flexDirection={FlexDirection.Column}
      maxWidth={'100%'}>

      <Table
        columns={columns}
        data={unassignedBookings.data}/>
    </ContainerFill>
  );
};
