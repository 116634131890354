export const Environment = {
  local: {
    name: 'Local',
    env: 'dev',
    uiHost: 'http://localhost:3000',
    uiBase: '/',
    apiHost: 'https://8q1cmoz4w0.execute-api.eu-west-1.amazonaws.com',
    apiBase: '/dev',
    navigatorType: 'browser',
  },
  development: {
    name: 'Development',
    env: 'dev',
    uiHost: 'https://backoffice-dev.glenskye.co.za',
    uiBase: '/',
    apiHost: 'https://8q1cmoz4w0.execute-api.eu-west-1.amazonaws.com',
    apiBase: '/dev',
    navigatorType: 'browser',
  },
  production: {
    name: 'Production',
    env: 'dev',
    uiHost: 'https://backoffice.glenskye.co.za',
    uiBase: '/',
    apiHost: 'https://8q1cmoz4w0.execute-api.eu-west-1.amazonaws.com',
    apiBase: '/dev',
    navigatorType: 'browser',
  }
}
