export const PropertyType = {
  Any: 'any',
  String: 'string',
  Number: 'number',
  Bool: 'bool',
  Array: 'array',
  Node: 'node',
  Object: 'object',
  Action: 'action',
  Logical: 'logical',
  Color: 'color',
};

export const WidgetType = {
  Container: 'container',
  Component: 'component',
  Template: 'template',
};

export const ToastType = {
  Notification: 'notification',
  Error: 'error',
};

export const Color = {
  Transparent: '#00000000',
  TransparentBlack: '#00000080',
  TransparentWhite: '#FFFFFF80',
  TransparentDarkGrey: '#33333380',
  TransparentGrey: '#66666660',
  TransparentBlue: '#007CCAA0',
  TransparentLightGrey: '#A3A3A360',
  TransparentLightBlue: '#007CCA20',
  TransparentLightGreen: '#A8B40020',
  TransparentOrange: '#FF4C0060',
  Black: '#000000',
  VeryDarkGrey: '#282828',
  DarkGrey: '#333333',
  Grey: '#666666',
  LightGrey: '#A3A3A3',
  VeryLightGrey: '#ececec',
  White: '#FFFFFF',
  LightRed: '#FF7276',
  Red: '#EE0000',
  DarkRed: '#880000',
  Green: '#00EE00',
  LightGreen: '#A8B400',
  Yellow: '#EBE61C',
  LightYellow: '#ffffe0',
  LighterBlue: '#80c5f6',
  LightBlue: '#52a8e5',
  Blue: '#007CCA',
  DarkBlue: '#0000CA',
  Orange: '#d8582a',
  GreyPink: '#e7dbe8',
  LightPink: '#c8a5ca',
  MidPink: '#af7ab2',
  DarkPurple: '#843388',
};

export const Shadow = {
  TopLeftOffsetLightGrey: `-5px 5px 15px ${Color.TransparentLightGrey}`,
};

export const FontSize = {
  Smallest: '6px',
  Smaller: '8px',
  Small: '12px',
  Normal: '16px',
  Big: '20px',
  Bigger: '38px',
  Biggest: '56px',
  Huge: '72px',
};

export const FontFamily = {
  Default: 'Verdana',
  Serif: 'serif',
  'Sans-Serif': 'sans-serif',
  Monospace: 'Monospace',
  'Times New Roman': 'Times New roman',
  Verdana: 'Verdana',
};

export const FontWeight = {
  Normal: 'normal',
  Bold: 'bold',
  100: '100',
  200: '200',
  300: '300',
  400: '400',
  500: '500',
  600: '600',
  700: '700',
  800: '800',
  900: '900',
};

export const FontStyle = {
  Normal: 'normal',
  Italic: 'italic',
};

export const TextAlign = {
  Auto: 'auto',
  Left: 'left',
  Right: 'right',
  Center: 'center',
  Justify: 'justify',
};

export const TextDecorationLine = {
  None: 'none',
  Underline: 'underline',
  LineThrough: 'line-through',
  UnderlineLineThrough: 'underline line-through',
};

export const TextTransform = {
  None: 'none',
  Uppercase: 'uppercase',
  Lowercase: 'lowercase',
  Capitalize: 'capitalize',
};

export const PointerEvents = {
  Auto: 'auto',
  None: 'none',
  BoxNone: 'box-none',
  BoxOnly: 'box-only',
};

export const Visibility = {
  Visible: 'visible',
  Hidden: 'hidden',
};

export const BorderStyle = {
  Solid: 'solid',
  Dotted: 'dotted',
  Dashed: 'dashed',
};

export const AlignContent = {
  Start: 'flex-start',
  End: 'flex-end',
  Center: 'center',
  Stretch: 'stretch',
  SpaceBetween: 'space-between',
  SpaceAround: 'space-around',
};

export const AlignItems = {
  Start: 'flex-start',
  End: 'flex-end',
  Center: 'center',
  Stretch: 'stretch',
  Baseline: 'baseline',
};

export const AlignSelf = {
  Auto: 'auto',
  Start: 'flex-start',
  End: 'flex-end',
  Center: 'center',
  Stretch: 'stretch',
  Baseline: 'baseline',
};

export const Direction = {
  Auto: 'auto',
  LeftToRight: 'ltr',
  RightToLeft: 'rtl',
};

export const Display = {
  None: 'none',
  Flex: 'flex',
  InlineFlex: 'inline-flex',
  Block: 'block'
};

export const FlexDirection = {
  Row: 'row',
  RowReverse: 'row-reverse',
  Column: 'column',
  ColumnReverse: 'column-reverse',
};

export const FlexWrap = {
  Wrap: 'wrap',
  NoWrap: 'nowrap',
  WrapReverse: 'wrap-reverse',
};

export const JustifyContent = {
  Start: 'flex-start',
  End: 'flex-end',
  Center: 'center',
  SpaceBetween: 'space-between',
  SpaceAround: 'space-around',
  SpaceEvenly: 'space-evenly',
};

export const Overflow = {
  Visible: 'visible',
  Hidden: 'hidden',
  Scroll: 'scroll',
};


export const Position = {
  Absolute: 'absolute',
  Relative: 'relative',
};

export const DataDetectorType = {
  PhoneNumber: 'phoneNumber',
  Link: 'link',
  Address: 'address',
  CalendarEvent: 'calendarEvent',
  Email: 'email',
  None: 'none',
  All: 'all',
};

export const EllipsizeMode = {
  Head: 'head',
  Middle: 'middle',
  Tail: 'tail',
  Clip: 'clip',
};

export const ResizeMode = {
  Cover: 'cover',
  Contain: 'contain',
  Stretch: 'stretch',
  Repeat: 'repeat',
  Center: 'center',
};

export const KeyboardType = {
  Default: 'default',
  Email: 'email-address',
  Numeric: 'numeric',
  PhonePad: 'phone-pad',
  NumberPad: 'number-pad',
  DecimalPad: 'decimal-pad',
  IOSAscii: 'ascii-capable',
  IOSNumbersAndPunctuation: 'numbers-and-punctuation',
  IOSUrl: 'url',
  IOSNamePhonePad: 'name-phone-pad',
  IOSTwitter: 'twitter',
  IOSWebSearch: 'web-search',
  AndroidVisiblePassword: 'visible-password',
};

export const ReturnKeyType = {
  Done: 'done',
  Go: 'go',
  Next: 'next',
  Search: 'search',
  Send: 'send',
  AndroidNone: 'none',
  AndroidPrevious: 'previous',
  IOSDefault: 'default',
  IOSEmergencyCall: 'emergency-call',
  IOSGoogle: 'google',
  IOSJoin: 'join',
  IOSRoute: 'route',
  IOSYahoo: 'yahoo',
};

export const BoxSizing = {
  ContentBox: 'content-box',
  BorderBox: 'border-box',
};

export const UserSelect = {
  None: 'none',
  Auto: 'auto',
};

export const Hyphens = {
  None: 'none',
  Manual: 'manual',
  Auto: 'auto',
};

export const TextOverflow = {
  Clip: 'clip',
  Ellipsis: 'ellipsis',
};

export const VerticalAlign = {
  Baseline: 'baseline',
  Sub: 'sub',
  Super: 'super',
  TextTop: 'text-top',
  TextBottom: 'text-bottom',
  Middle: 'middle',
  Top: 'top',
  Bottom: 'bottom',
};

export const WhiteSpace = {
  Normal: 'normal',
  Nowrap: 'nowrap',
  Pre: 'pre',
  PreWrap: 'pre-wrap',
  PreLine: 'pre-line',
  BreakSpaces: 'break-spaces',
};

export const WordBreak = {
  Normal: 'normal',
  BreakAll: 'break-all',
  KeepAll: 'keep-all',
};

export const ButtonType = {
  Primary: 'Primary',
  Secondary: 'Secondary',
  Link: 'Link'
};

export const AlertType = {
  Error: 'Error',
  Warning: 'Warning',
  Info: 'Info',
  Success: 'Success',
};
