export class Memoize {

  static promiseMap = new Map();

  /**
   * Saves the returned promise in memory to be reused multiple times.
   * Automatically clears the promise in memory if res.ok is false.
   * @param fnName {string} The name of the function you want to save.
   * @param fn {function} The async provider function reference you want to save
   * @param force {boolean} Force update the promise
   * @param [args] {any} The parameters you want to pass to your function in the order they are declared.
   * @returns {Promise} The promise from the function you called in.
   */
  /**
   * Saves the returned promise in memory to be reused multiple times.
   * Automatically clears the promise in memory if res.ok is false.
   * @param fnName {string} The name of the function you want to save.
   * @param fn {function} The async provider function reference you want to save
   * @param options {object} Additional options for memoization.
   * @param options.force? {boolean} Force update the promise
   * @param options.ttl? {number} Time (in seconds) to live for cache.
   * @param [args] {any} The parameters you want to pass to your function in the order they are declared.
   * @returns {Promise} The promise from the function you called in.
   */
  static providerFunction(
    fnName,
    fn,
    {
      force = false,
      ttl = null
    },
    ...args
  ) {

    try {

      if (typeof fnName !== 'string' || typeof fn !== 'function') {

        return Promise.reject('Invalid params passed to memoizeFunction');
      }

      if (Memoize.promiseMap.has(fnName) && !force) {

        const stored = Memoize.promiseMap.get(fnName);

        const hasExpired = typeof stored?.stamp === 'number' && typeof ttl === 'number' && ttl > 0
          ? (Date.now() - stored.stamp) >= (ttl * 1000)
          : false;

        if (!hasExpired) {

          return stored.promise;
        }
      }

      const promise = fn(...args);

      Memoize.promiseMap.set(fnName, {
        promise: promise,
        stamp: Date.now()
      });

      promise
        .then((res) => {

          if (!res.ok) {

            Memoize.promiseMap.delete(fnName);
          }
        });

      return promise;

    } catch (err) {

      console.warn(err.message);

      return fn(...args);
    }
  }

  /**
   * Remove promise from memory so that subsequent calls would call the function again
   * @param fnName {string} The name of the function provided in providerFunction.
   */
  static clearFunction(fnName) {

    if (typeof fnName !== 'string') {

      console.warn('Invalid parameter passed to clearFunction');

      return;
    }

    Memoize.promiseMap.delete(fnName);
  }

  /**
   * Removes all saved promises from memory.
   */
  static clearAll() {

    Memoize.promiseMap.clear();
  }
}
