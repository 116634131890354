import
  React
from 'react';

import {
  Navigate,
  useLocation
} from 'react-router-dom';

import {
  IdentityStatus
} from 'constants/auth';

import {
  NavigationPaths,
} from 'constants/navigation';

import {
  useAuth
} from 'hooks/useAuth';

export const ProtectedRoute = (props) => {

  const { isLoggedIn, identity } = useAuth();
  const location = useLocation();

  if (location.pathname !== NavigationPaths.Login && !isLoggedIn) {

    return <Navigate
      to={NavigationPaths.Login}
      replace={true}
      state={{
        from: location
      }}/>;
  }

  if (location.pathname !== NavigationPaths.NewPassword && identity?.status === IdentityStatus.NewPassword) {

    return <Navigate
      to={NavigationPaths.NewPassword}
      replace={true}
      state={{
        from: location
      }}/>;
  }

  return props.children;
}
