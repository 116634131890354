import
  React, {
  useState
} from 'react';

import {
  useAuth
} from 'hooks/useAuth';

import {
  IdentityApi
} from 'api';

import {
  ContainerFillHV,
  Card,
  FormGroup,
  FormLabel,
  FormMessage,
  Button,
  Input
} from 'ui/widgets';
import {Color} from 'constants/presentation';
import {IdentityStatus} from 'constants/auth';

export const NewPassword = (props) => {

  const { setIdentity } = useAuth();
  const [ state, setState ] = useState({
    isBusy: false,
    password: '',
    isPasswordValid: false,
    confirmPassword: '',
    isConfirmPasswordValid: false
  });

  const passwordChange = (value) => {

    setState({
      ...state,
      password: value,
      isPasswordValid: typeof value === 'string' && value.trim().length > 0
    });
  };

  const confirmPasswordChange = (value) => {

    setState({
      ...state,
      confirmPassword: value,
      isConfirmPasswordValid: typeof value === 'string' && value.trim().length > 0
    });
  };

  const onConfirmNewPassword = () => {

    if (state.isBusy || !state.isPasswordValid || !state.isConfirmPasswordValid ||
      state.password !== state.confirmPassword) {

      return;
    }

    setState({
      ...state,
      isBusy: true
    });

    IdentityApi
      .completeNewPassword(
        state.password,
        {}
      )
      .then(identity => {

        if (identity.status === IdentityStatus.LoggedIn) {

          setIdentity(identity);
          return;
        }

        throw Error('New password failed');
      })
      .catch(error => {

        console.warn(error);

        setState({
          ...state,
          isBusy: false
        });
      });
  };

  return (
    <ContainerFillHV>

      <Card
        title='New Password'
        width='400px'>

        <FormGroup>

          <FormLabel htmlFor='txt_NewPassword'>Password</FormLabel>
          <Input
            id='txt_username'
            type='password'
            value={state.password}
            onChange={passwordChange}/>

          { state.password.length > 0 && !state.isPasswordValid &&

            <FormMessage
              color={Color.Red}>
              Please enter a valid password
            </FormMessage>
          }
        </FormGroup>

        <FormGroup>

          <FormLabel htmlFor='txt_ConfirmPassword'>Confirm Password</FormLabel>

          <Input
            id='txt_ConfirmPassword'
            type='password'
            value={state.confirmPassword}
            onChange={confirmPasswordChange}/>

          { state.password.length > 0 && !state.isPasswordValid &&

            <FormMessage
              color={Color.Red}>
              Please enter a valid password
            </FormMessage>
          }

          { state.password !== state.confirmPassword &&

            <FormMessage
              color={Color.Red}>
              Passwords do not match!
            </FormMessage>
          }
        </FormGroup>

        <FormGroup>

          <Button
            disabled={
              !state.isPasswordValid ||
              !state.isConfirmPasswordValid ||
              state.password !== state.confirmPassword
            }
            text='Update Password'
            onPress={onConfirmNewPassword}/>
        </FormGroup>
      </Card>
    </ContainerFillHV>
  )
};
