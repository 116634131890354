import React from 'react';

import styled from 'styled-components';

import {
  baseTemplate,
  baseStyledPropTypes,
  baseStyledDefaultProps
} from 'ui/widgets/templates/baseTemplate';

import {
  Display,
  JustifyContent,
  AlignItems
} from 'constants/presentation';

const ContainerFillHVProto = styled.div`
  ${baseTemplate}
`;

export const ContainerFillHV = React.forwardRef((props, ref) => (
  <ContainerFillHVProto ref={ref} styled={props}>
    {props.children}
  </ContainerFillHVProto>
));

ContainerFillHV.propTypes = {
  ...baseStyledPropTypes
};

ContainerFillHV.defaultProps = {
  ...baseStyledDefaultProps,

  width: '100%',
  height: '100%',
  display: Display.Flex,
  justifyContent: JustifyContent.Center,
  alignItems: AlignItems.Center,
}
