import React from 'react';

import styled from 'styled-components';

import PropTypes from 'prop-types';

import {
  FaInfoCircle,
  FaExclamationTriangle,
  FaExclamationCircle,
  FaCheck,
  FaWindowClose
} from 'react-icons/fa';

import {
  Display,
  FlexDirection,
  AlignItems,
  AlertType,
  Color
} from 'constants/presentation';

import {
  baseTemplate,
  baseStyledPropTypes,
  baseStyledDefaultProps,
} from 'ui/widgets/templates/baseTemplate';

import {
  textTemplate,
  textStyledPropTypes,
  textStyledDefaultProps,
} from 'ui/widgets/templates/textTemplate';

const defaultStyle = {
  ...baseStyledDefaultProps,
  ...textStyledDefaultProps,

  padding: '15px',
  display: Display.Flex,
  flexDirection: FlexDirection.Row,
  alignItem: AlignItems.Center,
  borderRadius: '8px'
};

const getVariantStyle = variant => {

  switch (variant) {

    case AlertType.Error:
      return {
        ...defaultStyle,
        backgroundColor: Color.LightRed
      };

    case AlertType.Warning:
      return {
        ...defaultStyle,
        backgroundColor: Color.Yellow
      };

    case AlertType.Success:
      return {
        ...defaultStyle,
        backgroundColor: Color.LightGreen
      };

    case AlertType.Info:
    default:
      return {
        ...defaultStyle,
        backgroundColor: Color.LightBlue,
        color: Color.White,
      }
  }
};

const getVariantIcon = variant => {

  switch (variant) {

    case AlertType.Error:
      return <FaExclamationCircle color={Color.Black}/>

    case AlertType.Warning:
      return <FaExclamationTriangle color={Color.Orange}/>

    case AlertType.Success:
      return <FaCheck color={Color.Green}/>

    case AlertType.Info:
    default:
      return <FaInfoCircle color={Color.White}/>
  }
};

const Container = styled.div`
  ${baseTemplate},
`;

const Message = styled.span`
  ${textTemplate}
  flex: 1;
  margin: 0 10px
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  
  &:hover {
    opacity: 0.8;
  }
`;

export const Alert = props => {

  const style = getVariantStyle(props.type);
  const Icon = getVariantIcon(props.type);

  const handleClose = (e) => {
    e.preventDefault();

    typeof props.onClose === 'function' &&
      props.onClose();
  }

  return (

    <Container styled={style}>

      {Icon}

      <Message styled={style}>
        {props.message}
      </Message>

      <CloseButton onClick={handleClose}>
        <FaWindowClose color={style.color} size={20}/>
      </CloseButton>

    </Container>
  );
};

Alert.propTypes = {
  ...baseStyledPropTypes,
  ...textStyledPropTypes,

  type: PropTypes.oneOf(Object.values(AlertType)),
  message: PropTypes.string,
  onClose: PropTypes.func
};

Alert.defaultProps = {
  ...baseStyledDefaultProps,
  ...textStyledDefaultProps,

  type: AlertType.Info,
  message: '',
  onClose: () => {}
};
