import
  React
from 'react';

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SubMenu,
} from 'react-pro-sidebar';

import {
  Link
} from 'react-router-dom';

import {
  FaTachometerAlt,
  FaCalendarAlt,
  FaSignOutAlt
} from 'react-icons/fa';

import
  styled
from 'styled-components';

import {
  IdentityApi
} from 'api';

import {
  useAuth,
  useUnassignedBookings,
} from 'hooks';

import {
  Color,
} from 'constants/presentation';

import {
  NavigationPaths,
} from 'constants/navigation';

import {
  Badge
} from 'ui/widgets/Badge';

const Heading = styled.div`
  padding: 24px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SideBarButtonWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SideBarButton = styled.a`
  transition: width 0.3s;
  width: 150px;
  padding: 1px 15px;
  border-radius: 40px;
  background-color: rgba(255,255,255,0.05);
  color: #adadad;
  text-decoration: none;
  margin: 0 auto;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    background-color: #dee2ec;
  }
`;

const SideBarButtonText = styled.span`
  margin-left: 5px;
  font-size: 13px;
`;

export const SideBar = (props) => {

  const { onLogout } = useAuth();

  const {
    unassignedBookings,
  } = useUnassignedBookings();

  const handleLogout = (e) => {

    e.preventDefault();

    IdentityApi.logout()
    onLogout();
  };

  return (
    <ProSidebar
      breakPoint='md'>

      <SidebarHeader>
        <Heading>
          Glen-Skye
        </Heading>
      </SidebarHeader>

      <Menu iconShape='circle'>

        <MenuItem icon={<FaTachometerAlt/>}>
          Dashboard
          <Link to={NavigationPaths.Dashboard}/>
        </MenuItem>

        <SubMenu
          title='Bookings'
          icon={<FaCalendarAlt/>}
          suffix={
            unassignedBookings.data.length > 0 &&

            <Badge
              width={'20px'}
              height={'20px'}
              backgroundColor={Color.Red}>
              !
            </Badge>
          }>
          <MenuItem
            suffix={
              unassignedBookings.data.length > 0 &&
              <Badge>
                {unassignedBookings.data.length}
              </Badge>
            }>
            Unassigned
            <Link to={NavigationPaths.BookingsUnassigned}/>
          </MenuItem>
          <MenuItem>
            Schedule
            <Link to={NavigationPaths.BookingSchedule}/>
          </MenuItem>
        </SubMenu>
      </Menu>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <SideBarButtonWrapper>
          <SideBarButton onClick={handleLogout}>
            <FaSignOutAlt />
            <SideBarButtonText>
              Logout
            </SideBarButtonText>
          </SideBarButton>
        </SideBarButtonWrapper>
      </SidebarFooter>
    </ProSidebar>
  )
}
