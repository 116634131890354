import { IdentityRoutes } from 'ui/navigation/routes/identityRoutes';
import { DashboardRoutes } from 'ui/navigation/routes/dashboard';
import { BookingRoutes } from 'ui/navigation/routes/bookings';

export const Routes = [
  ...IdentityRoutes,
  ...DashboardRoutes,
  ...BookingRoutes,
];

export * from 'ui/navigation/sidebar';
export * from 'ui/navigation/protectedRoute';
export * from 'ui/navigation/Navigator';
