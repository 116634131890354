import {
  createContext,
  useContext,
  useState
} from 'react';

import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  NavigationPaths,
} from 'constants/navigation';

import {
  StorageApi
} from 'api/storage';

const AuthContext = createContext(null);

export const AuthProvider = (props) => {

  const [ identity, setIdentity ] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogin = async (identity) => {

    setIdentity(identity);
    await StorageApi.set('tokens', identity.tokens);

    const origin = location.state?.from?.pathname || NavigationPaths.Dashboard;
    navigate(origin);
  };

  const handleLogout = () => {

    setIdentity(null);
    StorageApi.reset();
    navigate(NavigationPaths.Login);
  };

  const value = {
    identity,
    isLoggedIn: !!identity,
    setIdentity: handleLogin,
    onLogout: handleLogout
  };

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {

  return useContext(AuthContext)
}
