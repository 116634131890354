import {
  NavigationPaths,
} from 'constants/navigation';

import {
  Dashboard,
} from 'ui/screens/dashboard';

export const DashboardRoutes = [
  {
    path: NavigationPaths.Dashboard,
    element: <Dashboard />,
    options: {
      default: true,
      protected: true,
    },
  },
]
