import React from 'react';

import styled from 'styled-components';

import PropTypes from 'prop-types';

import {
  baseTemplate,
  baseStyledDefaultProps,
  baseStyledPropTypes
} from 'ui/widgets/templates/baseTemplate';

import {
  textTemplate,
  textStyledDefaultProps,
  textStyledPropTypes
} from 'ui/widgets/templates/textTemplate';

const InputProto = styled.input`
  ${baseTemplate}
  ${textTemplate}
`;

export const Input = props => {

  const onChange = (e) => {

    typeof props.onChange === 'function' &&
      props.onChange(e.nativeEvent.target.value)
  };

  const handleKeyDown = (event) => {

    if (event.key === 'Enter') {
      typeof props.onSubmit === 'function' && props.onSubmit();
    }
  };

  return (

    <InputProto
      type={props.type}
      value={props.value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      styled={{...props}}/>
  );
};

Input.propTypes = {
  ...baseStyledPropTypes,
  ...textStyledPropTypes,

  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'text',
    'password'
  ]),
  onSubmit: PropTypes.func,
};

Input.defaultProps = {
  ...baseStyledDefaultProps,
  ...textStyledDefaultProps,

  padding: '0.5em',
  border: '1px solid',
  borderRadius: '4px',
  fontSize: 'inherit',
  width: '100%',

  id: '',
  onChange: () => {},
  value: '',
  type: 'text',
  onSubmit: () => {},
};
