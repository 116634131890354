/* Auth */

export const LoginFormState = {
  SignIn: 'SIGN_IN',
  ForgotPassword: 'FORGOT_PASSWORD',
};

export const IdentityStatus = {
  LoggedIn: 'LOGGED_IN',
  LoggedOut: 'LOGGED_OUT',
  NewPassword: 'NEW_PASSWORD',
  CodeSent: 'CODE_SENT',
  PasswordConfirmed: 'PASSWORD_CONFIRMED'
}
