import React from 'react';

import styled from 'styled-components';

import {
  Color,
  FontWeight,
  JustifyContent,
  AlignItems,
  Display,
} from 'constants/presentation';

import {
  baseTemplate,
  baseStyledPropTypes,
  baseStyledDefaultProps,
  textTemplate,
  textStyledPropTypes,
  textStyledDefaultProps,
} from 'ui/widgets/templates';

const BadgeProto = styled.span`
  ${baseTemplate}
  ${textTemplate}
`;

export const Badge = props => (

  <BadgeProto
    styled={props}>
    {props.children}
  </BadgeProto>
);

Badge.propTypes = {
  ...baseStyledPropTypes,
  ...textStyledPropTypes,
};

Badge.defaultProps = {
  ...baseStyledDefaultProps,
  ...textStyledDefaultProps,

  width: '28px',
  height: '28px',
  borderRadius: '14px',
  color: Color.White,
  backgroundColor: Color.LightBlue,
  fontWeight: FontWeight.Bold,
  display: Display.Flex,
  justifyContent: JustifyContent.Center,
  alignItems: AlignItems.Center,
};
