import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  useAuth
} from 'hooks/useAuth';

import {
  Navigator,
  Routes,
  SideBar,
} from 'ui/navigation';

const Layout = styled.div`
  height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

export const App = () => {

  const { isLoggedIn } = useAuth();

  return (
    <Layout>

      { isLoggedIn &&

        <SideBar/>
      }

      <Content>
        <Navigator routes={Routes}/>
      </Content>
    </Layout>
  )
};

export default App;
