import React from 'react';

import styled from 'styled-components';

import PropTypes from 'prop-types';

import {
  Triangle
} from 'react-loader-spinner';

import {
  AlignItems,
  Display,
  JustifyContent,
  ButtonType,
  Color, TextDecorationLine
} from 'constants/presentation';

import {
  baseTemplate,
  baseStyledDefaultProps,
  baseStyledPropTypes
} from 'ui/widgets/templates/baseTemplate';

import {
  textTemplate,
  textStyledDefaultProps,
  textStyledPropTypes
} from 'ui/widgets/templates/textTemplate';

const ButtonProto = styled.button`
  ${baseTemplate}

  &:hover {
    opacity: 0.8;
  }
`;

const Text = styled.span`
  ${textTemplate}
`;

const defaultStyle = {
  ...baseStyledDefaultProps,
  ...textStyledDefaultProps,

  display: Display.Flex,
  minHeight: '40px',
  minWidth: '100%',
  justifyContent: JustifyContent.Center,
  alignItems: AlignItems.Center,
  borderRadius: '40px',
  border: '1px solid',
  margin: '5px 0',
};

const getButtonVariantStyle = variant => {

  switch (variant) {

    default:
    case ButtonType.Primary:
      return {
        ...defaultStyle,
        backgroundColor: Color.LightBlue,
        borderColor: Color.LightBlue,
        color: Color.White,
      };

    case ButtonType.Secondary:
      return {
        ...defaultStyle
      };

    case ButtonType.Link:
      return {
        ...defaultStyle,
        color: Color.Blue,
        borderWidth: '0px',
        textDecoration: TextDecorationLine.Underline
      };
  }
}

export const Button = props => {

  const onPress = (e) => {

    e.preventDefault();

    if (props.disabled) {
      return;
    }

    typeof props.onPress === 'function' &&
      props.onPress();
  }

  const styled = getButtonVariantStyle(props.type);

  if (props.disabled) {
    styled.opacity = 0.5;
  }

  return (

    <ButtonProto
      onClick={onPress}
      styled={styled}>

      { props.busy

        ?  <Triangle
            color={styled.color}
            height={30}
            width={30} />

        : !!props.text

          ? <Text
              styled={styled}>

              {props.text}
            </Text>

          : props.children
      }
    </ButtonProto>
  );
};

Button.propTypes = {
  ...baseStyledPropTypes,
  ...textStyledPropTypes,

  disabled: PropTypes.bool,
  text: PropTypes.string,
  onPress: PropTypes.func,
  type: PropTypes.oneOf(Object.values(ButtonType)),
  busy: PropTypes.bool,
};

Button.defaultProps = {
  ...baseStyledDefaultProps,
  ...textStyledDefaultProps,

  display: Display.Flex,
  minHeight: '40px',
  minWidth: '100%',
  justifyContent: JustifyContent.Center,
  alignItems: AlignItems.Center,
  borderRadius: '40px',
  border: '1px solid',
  margin: '5px 0',

  disabled: false,
  text: '',
  onPress: () => {},
  type: ButtonType.Primary,
  busy: false
}
