import { FetchApi } from 'api/fetch';
import { Memoize } from 'api/memoize';
import dayjs from 'dayjs';

import {
  Color
} from 'constants/presentation';

export const BookingsApi = {

  getBookingColor: (status) => {

    if (typeof status !== 'string') {
      return '#80c5f6';
    }

    status = status.toLowerCase();

    switch (status) {
      case 'deposit_pending': return Color.LightRed;
      case 'full_pending': return Color.LightPink;
      case 'fully_paid': return Color.LightGreen;
      case 'refunded': return Color.VeryLightGrey;
      default: return Color.LighterBlue;
    }
  },

  getBookings: async (start, end) => {

    try {

      if (typeof start !== 'string') {

        return {
          ok: false,
          result: null,
          error: Error('Invalid start date')
        };
      }

      if (typeof end !== 'string') {

        return {
          ok: false,
          result: null,
          error: Error('Invalid end date')
        };
      }

      const res = await FetchApi.fetch(
        `/bookings?start=${start}&end=${end}`,
        {
          method: 'GET'
        }
      );

      if (!res.ok || !Array.isArray(res.result)) {

        res.ok = false;
        res.error = Error('Invalid response from service request received.');
        return res;
      }

      for (let bookingIndex = 0; bookingIndex < res.result.length; bookingIndex++) {

        const booking = res.result[bookingIndex];

        booking.start = dayjs(booking.arrival).format('YYYY-MM-DD 14:00:00');
        booking.end = dayjs(booking.departure).format('YYYY-MM-DD 10:00:00');
        booking.bgColor = BookingsApi.getBookingColor(booking.status);
        booking.showPopover = true;
      }

      return res;

    } catch (error) {

      return {
        ok: false,
        result: null,
        error: error
      }
    }
  },

  updateBooking: async (booking) => {

    try {

      if (typeof booking !== 'object') {

        return {
          ok: false,
          result: null,
          error: Error('Invalid start date')
        };
      }

      return await FetchApi.fetch(
        `/bookings`,
        {
          method: 'PUT',
          body: booking,
        }
      );

    } catch (error) {

      return {
        ok: false,
        result: null,
        error: error
      }
    }
  },

  getUnassignedBookings: (force = false) => {

    return Memoize.providerFunction(
      'getUnassignedBookings',
      BookingsApi.getUnassignedBookingsAsync,
      { force },
    );
  },

  getUnassignedBookingsAsync: async () => {

    try {

      console.log('fetch data');

      const res = await FetchApi.fetch(
        `/bookings/unassigned`,
        {
          method: 'GET'
        }
      );

      if (!res.ok || !Array.isArray(res.result)) {

        res.ok = false;
        res.error = Error('Invalid response from service request received.');
        return res;
      }

      for (let bookingIndex = 0; bookingIndex < res.result.length; bookingIndex++) {

        const booking = res.result[bookingIndex];

        booking.arrival = dayjs(booking.arrival).format('YYYY-MM-DD');
        booking.departure = dayjs(booking.departure).format('YYYY-MM-DD');
      }

      return res;

    } catch (error) {

      return {
        ok: false,
        result: null,
        error: error
      };
    }
  }
}
