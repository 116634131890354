import React from 'react';

import styled from 'styled-components';

import {
  baseTemplate,
  baseStyledPropTypes,
  baseStyledDefaultProps
} from 'ui/widgets/templates/baseTemplate';

const ContainerFillProto = styled.div`
  ${baseTemplate}
`;

export const ContainerFill = (props) => (
  <ContainerFillProto styled={props}>
    {props.children}
  </ContainerFillProto>
)

ContainerFill.propTypes = {
  ...baseStyledPropTypes
};

ContainerFill.defaultProps = {
  ...baseStyledDefaultProps,

  width: '100%',
  height: '100%',
}
