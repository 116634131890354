import
  React
from 'react';

import
  Scheduler, {
  SchedulerData,
  ViewTypes,
} from 'react-big-scheduler';

import {
  Triangle
} from 'react-loader-spinner';

import {
  ContainerFill,
  ContainerFillHV
} from 'ui/widgets';

import {
  useBookings,
  useRooms,
} from 'hooks';

import withDragDropContext from 'hoc/withDnDContext'
import {Color, FlexDirection, Position} from 'constants/presentation';
import dayjs from 'dayjs';

let schedulerData = null;

export const SchedulerV2 = withDragDropContext((props) => {

  const [
    state,
    setState,
  ] = React.useState({
    viewModel: null,
  });

  const {
    busy,
    bookings,
    selectNewDates,
    updateBooking,
  } = useBookings();

  const {
    rooms,
  } = useRooms();

  const containerRef = React.useRef();

  const prevClick = (schedulerData) => {

    schedulerData.prev();
    selectNewDates(schedulerData.startDate, schedulerData.endDate);
  };

  const nextClick = (schedulerData) => {

    schedulerData.next();
    selectNewDates(schedulerData.startDate, schedulerData.endDate);
  };

  const onSelectDate = (schedulerData, date) => {

    schedulerData.setDate(date);
    selectNewDates(
      date.startOf('month').format('YYYY-MM-DD'),
      date.endOf('month').format('YYYY-MM-DD')
    );
  };

  const eventClicked = (schedulerData, event) => {
    alert(`You just clicked an event: {id: ${event.id}, title: ${event.title}}`);
  };

  const ops1 = (schedulerData, event) => {
    alert(`You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}`);
  };

  const ops2 = (schedulerData, event) => {
    alert(`You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`);
  };

  const newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    // if (confirm(`Do you want to create a new event? {slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`)) {

    //   let newFreshId = 0;
    //   schedulerData.events.forEach((item) => {
    //     if (item.id >= newFreshId)
    //       newFreshId = item.id + 1;
    //   });

    //   let newEvent = {
    //     id: newFreshId,
    //     title: 'New event you just created',
    //     start: start,
    //     end: end,
    //     resourceId: slotId,
    //     bgColor: 'purple'
    //   }
    //   schedulerData.addEvent(newEvent);
    //   this.setState({
    //     viewModel: schedulerData
    //   })
    // }
  }

  const moveEvent = (schedulerData, event, slotId, slotName, start, end) => {

    if (event.roomId === slotId) {
      return;
    }

    const originalRoomId = event.roomId;
    const originalResourceId = event.resourceId;

    event.roomId = slotId;
    event.resourceId = slotId;

    schedulerData.moveEvent(event, slotId, slotName, event.start, event.end);

    updateBooking(event)
      .then((updated) => {

        if (!updated) {

          event.roomId = originalRoomId;
          event.resourceId = originalResourceId;
          schedulerData.moveEvent(event, originalRoomId, slotName, event.start, event.end);
        }
      });
  };

  const toggleExpandFunc = (schedulerData, slotId) => {

    schedulerData.toggleExpandStatus(slotId);
    setState(prevState => ({
      ...prevState,
      viewModel: schedulerData,
    }));
  };

  const renderTodayCellHeader = (schedulerData, item, formattedDateItems, style) => {

    let datetime = schedulerData.localeMoment(item.time);
    let isCurrentDate = false;

    if (schedulerData.viewType === ViewTypes.Day) {
      isCurrentDate = datetime.isSame(new Date(), 'hour');
    } else {
      isCurrentDate = datetime.isSame(new Date(), 'day');
    }

    if (isCurrentDate) {

      style.backgroundColor = '#118dea';
      style.color = 'white';
    }

    return (

      <th key={item.time} className={`header3-text`} style={style}>
        {
          formattedDateItems.map((formattedItem, index) => (
            <div key={index}
                 dangerouslySetInnerHTML={{__html: formattedItem.replace(/[0-9]/g, '<b>$&</b>')}}/>
          ))
        }
      </th>
    );
  }

  React.useEffect(() => {

    console.log('here again');

    if (!schedulerData) {

      const today = dayjs().format('YYYY-MM-DD');

      schedulerData = new SchedulerData(
        today,
        ViewTypes.Month,
        false,
        false,
        {
          schedulerWidth: `${containerRef?.current?.offsetWidth - 30}`,
          views: [
            { viewName: 'Month', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false },
          ]
        }
    );

      schedulerData.localeMoment.locale('en');
    }

    if (!!rooms && rooms.length > 0) {
      schedulerData.setResources(rooms);
    }

    if (!!rooms && !!bookings) {
      schedulerData.setEvents(bookings);
    }

    setState(prevState => ({
      ...prevState,
      viewModel: schedulerData,
    }));

  }, [
    rooms,
    bookings,
  ]);

  if (!state.viewModel) {

    return (
      <ContainerFillHV
        ref={containerRef}
        flexDirection={FlexDirection.Column}>
        <Triangle
          color={Color.LightBlue}
          height={150}
          width={150} />
        <div>Loadings</div>
      </ContainerFillHV>
    )
  }

  return (

    <React.Fragment>
      <ContainerFill
        padding={'15px'}
        position={'relative'}
        display={'block'}
        maxWidth={'100%'}>
        <Scheduler
          schedulerData={state.viewModel}
          prevClick={prevClick}
          nextClick={nextClick}
          onSelectDate={onSelectDate}
          eventItemClick={eventClicked}
          onViewChange={() => {}}
          viewEventText='Edit'
          viewEventClick={ops1}
          viewEvent2Text='Delete'
          viewEvent2Click={ops2}
          moveEvent={moveEvent}
          newEvent={newEvent}
          toggleExpandFunc={toggleExpandFunc}
          nonAgendaCellHeaderTemplateResolver={renderTodayCellHeader}/>
      </ContainerFill>

      { busy &&

        <ContainerFillHV
          position={Position.Absolute}
          top={'0'}
          left={'0'}
          width={'100%'}
          height={'100%'}
          zIndex={2}
          backgroundColor={'#00000033'}>

          <Triangle
            color={Color.LightBlue}
            height={150}
            width={150} />
        </ContainerFillHV>
      }
    </React.Fragment>
  );
});
