import
  React
from 'react';

import
  styled
from 'styled-components';

import
  PropTypes
from 'prop-types';

import {
  Color,
  Display,
  FlexDirection
} from 'constants/presentation';

import {
  baseStyledDefaultProps,
  baseStyledPropTypes,
  baseTemplate
} from 'ui/widgets/templates/baseTemplate';
import {Triangle} from 'react-loader-spinner';

const Container = styled.div`
  ${baseTemplate}
`;

const Title = styled.span`
  font-weight: bolder;
  font-size: 35px;
  margin-bottom: 10px;
`;

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Card = (props) => {

  return (

    <Container styled={{...props}}>

      <Title>{props.title}</Title>

      { props.isLoading

        ? <LoaderContainer>

            <Triangle
              color={props.spinnerColor}
              height={props.spinnerSize}
              width={props.spinnerSize} />

            {props.loadingText}
          </LoaderContainer>

        : props.children
      }
    </Container>
  )
};

Card.propTypes = {
  ...baseStyledPropTypes,

  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,

  spinnerSize: PropTypes.number,
  spinnerColor: PropTypes.string,
  loadingText: PropTypes.string,
};

Card.defaultProps = {
  ...baseStyledDefaultProps,

  display: Display.Flex,
  flexDirection: FlexDirection.Column,
  backgroundColor: Color.White,
  borderRadius: '8px',
  padding: '20px',
  minWidth: '25em',

  title: '',
  isLoading: false,

  spinnerSize: 100,
  spinnerColor: Color.LightBlue,
  loadingText: 'Loading...'
};
