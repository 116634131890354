import {
  StorageApi,
} from 'api/storage';

const joinPaths = (...paths) => paths.join('/').replace(/\/\/+/g, '/').replace(/^(.*?):\//g,'$1://');
const isRelativePath = (path) => !path.startsWith('http');

let currentEnvironment = null;

export const init = async (config) => {

  if (!config) {
    //Environment.insight.error(new Error('Invalid environment config provided.'));
    return;
  }

  if (Object.keys(config).length === 0) {
    //Environment.insight.error(new Error('No environments configured'));
    return;
  }

  const environments = {};

  for (const [envName, envConfig] of Object.entries(config)) {

    if (!envConfig?.uiHost?.length || !envConfig?.apiHost?.length) {
      //Environment.insight.warn(`Invalid config given for ${envName}. ${envName} will not be configured.`);
      continue;
    }

    envConfig.name = envConfig.name || envName;
    envConfig.uiBase = envConfig.uiBase || '/';
    envConfig.apiBase = envConfig.apiBase || '/';
    envConfig.assetBase = envConfig.assetBase || '/';
    envConfig.assetHost = envConfig.assetHost || envConfig.uiHost

    if (!isRelativePath(envConfig.uiBase) || !isRelativePath(envConfig.apiBase) || !isRelativePath(envConfig.assetBase)) {
      //Environment.insight.warn(`Base path(s) in '${envName}' are not relative. All base paths must be relative.`);
    }

    if (!envConfig.uiHost.toLowerCase?.().startsWith('https') && envName?.toLowerCase?.() !== 'local') {
      //Environment.insight.warn(`http:// protocol being used on non local environment '${envName}'. Only https is supported.`);
    }

    if (envConfig.commonAssetPath?.length > 0 && isRelativePath(envConfig.commonAssetPath)) {
      //Environment.insight.warn(`Common asset path in '${envName}' is relative. Only absolute URLs are supported.`);
    }

    envConfig.apiPath = joinPaths(envConfig.apiHost, envConfig.apiBase);
    envConfig.uiPath = joinPaths(envConfig.uiHost, envConfig.uiBase);
    envConfig.assetPath = joinPaths(envConfig.assetHost, envConfig.assetBase);

    envConfig.navigatorType = envConfig.navigatorType || envConfig.uiHost.includes?.('localhost')
      ? 'browser'
      : 'memory';

    environments[envName.toLowerCase()] = envConfig;
  }

  await StorageApi.set(
    'environments',
    environments
  );

  let current = environments.prod || environments.production || {};

  const currentHost = window?.location?.hostname?.toLowerCase?.();

  for (const envConfig of Object.values(environments)) {

    const envHost = envConfig.uiHost.replace?.(/^https?:\/\//, '').replace(/:[0-9]+/, '');

    if (envHost === currentHost) {

      current = envConfig;
      break;
    }
  }

  await StorageApi.set(
    'currentEnvironment',
    current
  );

  currentEnvironment = current;

  console.log('Environment', current);
}

export const getCurrentEnv = () => currentEnvironment;
