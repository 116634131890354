import React from 'react';

import {
  RoomsApi
} from 'api/rooms';

import {
  useAuth,
} from './useAuth';

export const useRooms = () => {

  const [
    state,
    setState,
  ] = React.useState({
    rooms: [],
  });

  const { isLoggedIn } = useAuth();

  React.useEffect(() => {

    if (!isLoggedIn) {
      return;
    }

    RoomsApi
      .getAllRooms()
      .then((res) => {

        if (!res.ok || !res.parsedResult) {

          console.error(res.error);//TODO: Sentry
          return;
        }

        setState(prevState => ({
          ...prevState,
          rooms: res.parsedResult,
        }));
      })
  }, [
    isLoggedIn,
  ]);

  return {
    rooms: state.rooms
  }
}
