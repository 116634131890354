import React from 'react';

import {
  Outlet,
} from 'react-router-dom';

export const Bookings = () => {

  return (
    <Outlet />
  );
}
