import { FetchApi } from 'api/fetch';
import { Memoize } from 'api/memoize';

export const RoomsApi = {

  getAllRooms: (force = false) => {

    return Memoize.providerFunction(
      'getAllRooms',
      RoomsApi.getAllRoomsAsync,
      { force }
    );
  },
  getAllRoomsAsync: async () => {

    try {

      const res = await FetchApi.fetch(
        '/rooms',
        {
          method: 'GET'
        }
      );

      if (!res.ok || !Array.isArray(res.result) || res.result.length < 1) {

        res.ok = false;
        res.error = Error('Invalid response from service request received.');
        return res;
      }

      let resourceArray = [];

      for (let roomIndex = 0; roomIndex < res.result.length; roomIndex++) {

        const room = res.result[roomIndex];

        if (!room.roomId || !room.name) {

          continue;
        }

        resourceArray.push({
          id: room.roomId,
          parentId: room.parentId,
          name: room.name,
          groupOnly: false,
        });
      }

      res.parsedResult = resourceArray.sort((a, b) => {

        if (a.id < b.id) {
          return -1;
        }

        if (b.id < a.id) {
          return 1;
        }
        return 0;
      });

      return res;

    } catch (error) {

      return {
        ok: false,
        result: null,
        error: error
      }
    }
  }
}
