import
  React
from 'react';

import {
  useAuth
} from 'hooks/useAuth';

export const Dashboard = () => {

  const { token } = useAuth();

  return (
    <div>
      Dashboard
      <p>Token: {token}</p>
    </div>
  )
}
