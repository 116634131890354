import
  React
from 'react';

import
  ReactDOM
from 'react-dom';

import 'index.css';
import 'react-pro-sidebar/dist/css/styles.css';
import 'react-big-scheduler/lib/css/style.css';

import {
  BrowserRouter as Router,
} from 'react-router-dom';

import {
  RecoilRoot,
} from 'recoil';

import {
  init
} from 'config/environment';

import {
  Environment
} from 'environment';

import
  App
from 'App';

import {
  AuthProvider
} from 'hooks/useAuth';

import
  reportWebVitals
from 'reportWebVitals';

init(Environment);

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Router>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
