import React from 'react';

import PropTypes from 'prop-types';

import {
  useRoutes,
  Navigate,
} from 'react-router-dom';

import {
  ProtectedRoute,
} from 'ui/navigation/protectedRoute';

const createRoutes = (routes) => {

  if (!Array.isArray(routes) || routes.length < 1) {
    return [];
  }

  const routeObjects = [];
  let defaultRouteSet = false;

  for (const route of routes) {

    if (!route.path || !route.element) {
      continue;
    }

    const options = (!!route.options && route.options) || {};

    if (!defaultRouteSet && !!options.default) {

      defaultRouteSet = true;

      routeObjects.push({
        path: '*',
        element: (
          <Navigate
            to={route.path}
            replace={true}/>
        ),
        index: true,
      });
    }

    const isProtected = typeof options?.protected === 'boolean'
      ? options.protected
      : true;

    if (isProtected) {

      route.element = (
        <ProtectedRoute>
          {route.element}
        </ProtectedRoute>
      );
    }

    Array.isArray(route.children) && route.children.length > 0
      ? routeObjects.push({
          ...route,
          children: createRoutes(route.children),
        })
      : routeObjects.push(route);
  }

  return routeObjects;
};

export const Navigator = props => {

  const routes = React.useMemo(
    () => createRoutes(props.routes),
    [ props.routes ],
  );

  return useRoutes(routes);
};

Navigator.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      element: PropTypes.element.isRequired,
      path: PropTypes.string.isRequired,
      options: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
          protected: PropTypes.bool,
          default: PropTypes.bool,
        }),
      ]),
    }),
  ).isRequired,
};

Navigator.defaultProps = {};
