import {
  atom,
  selector,
} from 'recoil';

export const UnassignedBookingsAtom = atom({
  key: 'unassignedBookings',
  default: {
    loading: true,
    data: [],
  },
});

export const UnassignedCountSelector = selector({
  key: 'unassignedCount',
  get: ({ get }) => {

    const unassignedBookings = get(UnassignedBookingsAtom);
    return unassignedBookings.data.length;
  }
});
