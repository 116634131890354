import {
  NavigationPaths,
} from 'constants/navigation';

import {
  Login,
  NewPassword,
} from 'ui/screens/identity';

export const IdentityRoutes = [
  {
    path: NavigationPaths.Login,
    element: <Login />,
    options: {},
  },
  {
    path: NavigationPaths.NewPassword,
    element: <NewPassword />,
    options: {
      protected: true,
    },
  }
]
