import React from 'react';

import {
  useRecoilState,
} from 'recoil';

import {
  BookingsApi,
} from 'api';

import {
  UnassignedBookingsAtom,
} from 'atoms';

import {
  useAuth
} from 'hooks/useAuth';

export const useUnassignedBookings = () => {

  const { isLoggedIn } = useAuth();

  const [
    unassignedBookings,
    setUnassignedBookings,
  ] = useRecoilState(UnassignedBookingsAtom);

  React.useEffect(() => {

    if (!isLoggedIn) {
      return;
    }

    BookingsApi
      .getUnassignedBookings()
      .then((res) => {

        if (!res?.ok) {

          console.error(res.error);
          alert('oops');
          return;
        }

        setUnassignedBookings({
          loading: false,
          data: res.result,
        });
      });

  }, [
    isLoggedIn,
    setUnassignedBookings,
  ]);

  const updateUnassignedBookings = (booking) => {

    setUnassignedBookings({
      loading: true,
      data: [],
    });

    BookingsApi
      .updateBooking(booking)
      .then((res) => {

        if (res && res.ok) {

          BookingsApi
            .getUnassignedBookings(true)
            .then((res) => {

              if (!res?.ok) {

                console.error(res.error);
                alert('oops');
                return;
              }

              setUnassignedBookings({
                loading: false,
                data: res.result,
              });
            });

          return;
        }

        //TODO: Error handling
        console.log(res.error);
      })
  };

  return {
    unassignedBookings,
    updateUnassignedBookings,
  };
}
