import React from 'react';
import dayjs from 'dayjs';

import {
  BookingsApi,
} from 'api';

import {
  useAuth
} from 'hooks/useAuth';

const Bookings = new Map();
const EmptyArray = [];

export const useBookings = () => {

  const [
    state,
    setState,
  ] = React.useState({
    busy: true,
    start: '',
    end: '',
    currentKey: '',
  });

  const { isLoggedIn } = useAuth();

  React.useEffect(() => {

    if (!isLoggedIn) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      busy: true,
    }));

    const start = !!state.start
      ? state.start
      : dayjs().startOf('month').format('YYYY-MM-DD');

    const end = !!state.end
      ? state.end
      : dayjs().endOf('month').format('YYYY-MM-DD');

    const key = `${start}-${end}`;

    if (Bookings.has(key) && Bookings.get(key).length > 0) {

      setState(prevState => ({
        ...prevState,
        currentKey: key,
        busy: false,
      }));
      return;
    }

    BookingsApi
      .getBookings(start, end)
      .then((res) => {

        if (!res.ok) {

          console.error(res?.error);
          return;
        }

        Bookings.set(key, res.result);

        setState((prevState) => ({
          ...prevState,
          currentKey: key,
          busy: false,
        }));
      });

  }, [
    isLoggedIn,
    state.start,
    state.end,
  ]);

  const selectNewDates = (start, end) => {

    setState(prevState => ({
      ...prevState,
      start,
      end,
    }));
  };

  const updateBooking = async (booking) => {

    setState(prevState => ({
      ...prevState,
      busy: true,
    }));

    const res = await BookingsApi.updateBooking(booking)

    if (res && res.ok) {

      setState(prevState => ({
        ...prevState,
        busy: false,
      }));

      return true;
    }

    console.error(res.error); //TODO: Sentry

    setState(prevState => ({
      ...prevState,
      busy: false,
    }));

    return false;
  };

  return {
    busy: state.busy,
    bookings: Bookings.has(state.currentKey)
      ? Bookings.get(state.currentKey)
      : EmptyArray,
    selectNewDates,
    updateBooking,
  };
}
