import
  styled
from 'styled-components';

import {
  Display, FlexDirection
} from 'constants/presentation';

import {
  baseStyledDefaultProps,
  baseStyledPropTypes,
  baseTemplate
} from 'ui/widgets/templates/baseTemplate';

const FormGroupProto = styled.div`
  ${baseTemplate}
`;

export const FormGroup = props => (

  <FormGroupProto styled={{...props}}>
    {props.children}
  </FormGroupProto>
);

FormGroup.propTypes = {
  ...baseStyledPropTypes
};

FormGroup.defaultProps = {
  ...baseStyledDefaultProps,

  display: Display.Flex,
  flexDirection: FlexDirection.Column,
  margin: '5px 0'
};
