import
  React, {
  useState,
  useEffect
} from 'react';

import {
  LoginFormState
} from 'constants/auth';

import {
  Color,
  ButtonType,
  AlertType,
} from 'constants/presentation';

import {
  useAuth
} from 'hooks/useAuth';

import {
  IdentityApi
} from 'api';

import {
  ContainerFillHV,
  Card,
  FormGroup,
  FormLabel,
  FormMessage,
  Button,
  Input,
  Alert
} from 'ui/widgets';

export const Login = (props) => {

  const { setIdentity } = useAuth();
  const [ state, setState ] = useState({
    isLoading: true,
    isBusy: false,
    loginState: LoginFormState.SignIn,
    username: '',
    isUsernameValid: false,
    password: '',
    isPasswordValid: false,
    showError: false,
    error: ''
  });

  const alertClose = () => {

    setState({
      ...state,
      showError: false,
      error: ''
    });
  }

  const usernameChange = (value) => {

    setState({
      ...state,
      username: value,
      isUsernameValid: typeof value === 'string' && value.trim().length > 0
    });
  };

  const passwordChange = (value) => {

    setState({
      ...state,
      password: value,
      isPasswordValid:  typeof value === 'string' && value.trim().length > 0
    });
  };

  const forgotPassword = () => {

    setState({
      ...state,
      loginState: LoginFormState.ForgotPassword
    });
  }

  const backToSignIn = () => {

    setState({
      ...state,
      loginState: LoginFormState.SignIn
    });
  }

  const onSignIn = () => {

    if (state.isBusy || !state.isUsernameValid || !state.isPasswordValid) {

      return;
    }

    setState({
      ...state,
      isBusy: true,
      showError: false,
      error: ''
    });

    IdentityApi
      .login(
        state.username,
        state.password
      )
      .then(identity => {

        setIdentity(identity);
      })
      .catch((err) => {

        setState({
          ...state,
          showError: true,
          error: err.message,
          isBusy: false
        });
      });
  };

  useEffect(() => {

    IdentityApi
      .refresh()
      .then(identity => {

        setIdentity(identity);
      })
      .catch(() => {

        setState(prevState => ({
          ...prevState,
          isLoading: false
        }));
      });

  }, [ setIdentity ]);

  return (

    <ContainerFillHV
      backgroundSize='cover'
      backgroundImage='url(/assets/images/bg_Login.jpeg)'>

      { state.loginState === LoginFormState.SignIn &&

        <Card
          isLoading={state.isLoading}
          loadingText='Verifying identity...'
          title='Login'
          width='400px'>

          { state.showError &&

            <Alert
              onClose={alertClose}
              message={state.error}
              margin='5px'
              type={AlertType.Error}/>
          }

          <FormGroup marginTop={'3em'}>

            <FormLabel htmlFor='txt_username'>Username</FormLabel>

            <Input
              id='txt_username'
              type='text'
              value={state.username}
              onChange={usernameChange}/>

            { state.username.length > 0 && !state.isUsernameValid &&

              <FormMessage
                color={Color.Red}>
                Please enter a valid username
              </FormMessage>
            }
          </FormGroup>

          <FormGroup>

            <FormLabel htmlFor='txt_password'>Password</FormLabel>

            <Input
              id='txt_password'
              type='password'
              value={state.password}
              onSubmit={onSignIn}
              onChange={passwordChange}/>

            { state.password.length > 0 && !state.isPasswordValid &&

              <FormMessage
                color={Color.Red}>
                Please enter a valid password
              </FormMessage>
            }
          </FormGroup>

          <FormGroup>

            <Button
              disabled={!state.isUsernameValid || !state.isPasswordValid}
              text='Sign In'
              busy={state.isBusy}
              onPress={onSignIn}/>

            <Button
              text='Forgot password'
              type={ButtonType.Link}
              onPress={forgotPassword}/>
          </FormGroup>
        </Card>
      }

      { state.loginState === LoginFormState.ForgotPassword &&

        <Card title='Forgot Password'>

          <FormGroup>
            <FormLabel htmlFor='txt_username'>Username</FormLabel>
            <Input
              id='txt_username'
              type='text'
              value={state.username}
              onChange={usernameChange}/>
          </FormGroup>

          <FormGroup>
            <Button
              text='Forgot Password'
              onPress={forgotPassword}/>

            <Button
              text='Back to sign in'
              type={ButtonType.Link}
              onPress={backToSignIn}/>
          </FormGroup>
        </Card>
      }
    </ContainerFillHV>
  )
};
