import { StorageApi } from 'api/storage';
import { getCurrentEnv } from 'config/environment';

export const FetchApi = {

  processHeaders: async ({
    excludeBearer = false
  }) => {

    const defaultHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    if (!excludeBearer) {

      const tokens = await StorageApi.get('tokens');

      defaultHeaders['Authorization'] = `Bearer ${tokens.access}`;
    }

    return defaultHeaders;
  },

  resolveFetchRequestIntoApiResponse: async (res) => {

    const ret = {
      ok: res.ok,
      status: res.status,
      result: null,
      error: null
    };

    if (res.headers.get('Content-Type') === 'application/json') {
      ret.result = await res.json();
    } else {
      ret.result = await res.text();
    }

    //TODO: Handle Error

    return ret;
  },

  fetch: async (url, {
    method = 'GET',
    body = null,
    excludeBearer = false
  } = {}) => {
    try {

      if (typeof url !== 'string') {
        throw new Error('Invalid URL');
      }

      const headers = await FetchApi.processHeaders({
        excludeBearer
      });

      body = !!body
        ? JSON.stringify(body)
        : null;

      const env = getCurrentEnv();

      if (url.startsWith('{BASE}')) {
        url = url.replace('{BASE}', env.apiPath);
      }

      if (!url.startsWith('http')) {
        url = `${env.apiPath}${url}`
      }

      const res = await fetch(
        url,
        {
          method,
          body,
          headers
        }
      );

      return await FetchApi.resolveFetchRequestIntoApiResponse(res);

    } catch (error) {

      return {
        ok: false,
        result: null,
        error: error,
      };
    }
  }
}
