import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';

import {
  AwsConfig
} from 'config';

import {
  IdentityStatus
} from 'constants/auth';

const UserPool = new CognitoUserPool({
  UserPoolId: AwsConfig.cognito.userPoolId,
  ClientId: AwsConfig.cognito.appId,
});

const extractTokens = result => {

  if (!result) {

    return {
      access: '',
      refresh: '',
      id: '',
    }
  }

  const accessToken = result
    .getAccessToken()
    .getJwtToken();

  const refreshToken = result
    .getRefreshToken()
    .getToken();

  const idToken = result
    .getIdToken()
    .getJwtToken();

  return {
    access: accessToken,
    refresh: refreshToken,
    id: idToken,
  }
}

export const IdentityApi = {

  CognitoUser: UserPool.getCurrentUser(),

  getSession: async () => {

    return new Promise((
      resolve,
      reject,
    ) => {

      if (!IdentityApi.CognitoUser) {
        resolve();
      }

      IdentityApi.CognitoUser.getSession((
        e,
        session
      ) => (e && reject(e)) || resolve(session));
    });
  },

  logout: () => {

    IdentityApi.CognitoUser && IdentityApi.CognitoUser.signOut();
    IdentityApi.CognitoUser = null;

    return {
      destination: undefined,
      user: undefined,
      tokens: {
        access: '',
        refresh: '',
        id: '',
      },
      status: IdentityStatus.LoggedOut,
      userAttributes: undefined,
    };
  },

  login: async (
    user,
    password,
  ) => {

    const authDetails = new AuthenticationDetails({
      Username: user,
      Password: password,
    });

    IdentityApi.CognitoUser = new CognitoUser({
      Username: user,
      Pool: UserPool,
    });

    return new Promise((
      resolve,
      reject,
    ) => {

      IdentityApi.CognitoUser.authenticateUser(
        authDetails, {
          onSuccess: result => {

            resolve({
              destination: undefined,
              user,
              tokens: extractTokens(result),
              status: IdentityStatus.LoggedIn,
              userAttributes: undefined,
            });
          },

          newPasswordRequired: (
            userAttributes,
            requiredAttributes
          ) => {

            delete userAttributes.email_verified;
            delete userAttributes.phone_number_verified;

            resolve({
              destination: undefined,
              user,
              tokens: extractTokens(),
              status: IdentityStatus.NewPassword,
              userAttributes,
            });
          },

          onFailure: e => reject(e),
        });
    });
  },

  completeNewPassword: async (
    newPassword,
    userAttributes,
  ) => {

    console.warn(userAttributes);

    return new Promise((
      resolve,
      reject,
    ) => {

      if (!IdentityApi.CognitoUser) {

        reject('Attempted to complete new password with invalid user');
        return;
      }

      IdentityApi.CognitoUser.completeNewPasswordChallenge(
        newPassword,
        userAttributes, {
          onSuccess: result => {

            resolve({
              destination: undefined,
              user: IdentityApi.CognitoUser.getUsername(),
              tokens: extractTokens(result),
              status: IdentityStatus.LoggedIn,
              userAttributes,
            });
          },
          onFailure: e => reject(e),
        }
      );
    });
  },

  forgot: async user => {

    return new Promise((
      resolve,
      reject,
    ) => {

      if (!user) {

        reject('Attempted to complete new password with invalid user');
        return;
      }

      IdentityApi.CognitoUser = new CognitoUser({
        Username: user,
        Pool: UserPool,
      });

      IdentityApi.CognitoUser.forgotPassword({
          onSuccess: result => {

            resolve({
              destination: result.CodeDeliveryDetails.Destination,
              user,
              tokens: extractTokens(),
              status: IdentityStatus.CodeSent,
              userAttributes: undefined,
            });
          },
          onFailure: e => reject(e),
        }
      );
    });
  },

  confirmPassword: async (
    code,
    password,
  ) => {

    return new Promise((
      resolve,
      reject,
    ) => {

      if (!IdentityApi.CognitoUser) {

        reject('Attempted to complete new password with invalid user');
        return;
      }

      IdentityApi.CognitoUser.confirmPassword(
        code,
        password, {
          onSuccess: () => {

            resolve({
              destination: undefined,
              user: IdentityApi.CognitoUser.getUsername(),
              tokens: extractTokens(),
              status: IdentityStatus.PasswordConfirmed,
              userAttributes: undefined,
            });
          },
          onFailure: e => reject(e),
        });
    });
  },

  changePassword: async (
    oldPassword,
    newPassword,
  ) => {

    return new Promise((
      resolve,
      reject,
    ) => {

      if (!IdentityApi.CognitoUser) {

        reject('Attempted to change password with invalid session');
        return;
      }

      IdentityApi.CognitoUser.changePassword(
        oldPassword,
        newPassword, (
          e,
          result
        ) => (e && reject(e)) || resolve(result),
      );
    });
  },

  refresh: async () => {

    const session = await IdentityApi.getSession();

    let refreshToken = session
      ?.getRefreshToken()
      ?.getToken();

    return new Promise((
      resolve,
      reject
    ) => {

      if (!IdentityApi.CognitoUser) {

        reject(Error('Session expired please login again to continue'));
        return;
      }

      if (!refreshToken) {

        reject(Error('Session expired please sign in again to continue'));
        return;
      }

      IdentityApi.CognitoUser.refreshSession({
          RefreshToken: refreshToken,
          getToken: () => refreshToken,
        },
        (
          e,
          result
        ) => {

          if (e || !result) {

            reject(e);
            return;
          }

          resolve({
            destination: undefined,
            user: IdentityApi.CognitoUser.getUsername(),
            tokens: extractTokens(result),
            status: IdentityStatus.LoggedIn,
            userAttributes: undefined,
          });
        });
    });
  },
}
