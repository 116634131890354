import React from 'react';

import styled from 'styled-components';

import {
  textTemplate,
  textStyledDefaultProps,
  textStyledPropTypes
} from 'ui/widgets/templates/textTemplate';

const FormMessageProto = styled.label`
  ${textTemplate}
`;

export const FormMessage = props => (

  <FormMessageProto
    styled={props}>
    {props.children}
  </FormMessageProto>
);

FormMessage.propTypes = {
  ...textStyledPropTypes
};

FormMessage.defaultProps = {
  ...textStyledDefaultProps
};
